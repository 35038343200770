<template>
    <div class="achievement_pages">
        <div class="achievement_pages_left">
            <div class="achievement_pages_left_top">
                <div class="achievement_pages_left_top_left">
                    <div class="achievement_pages_left_top_left_title">
                        标志物
                    </div>
                    <div class="achievement_pages_left_top_left_time">
                        截至 2023-03-22
                    </div>
                    <div class="transcript">
                        <div class="result">
                            <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240819/899383F709A34B67A972DDA573BB7C51.png"
                                class="wh100">
                        </div>
                        <div class="sleep">
                            <div class="sleep_list">
                                <div class="sleep_text">
                                    <div class="sleep_text_left">
                                        真实年龄
                                    </div>
                                    <div class="sleep_text_right" v-if="infoList.userInfo?.userAge">
                                        {{ infoList.userInfo.userAge }}岁
                                    </div>
                                </div>
                                <div class="sleep_line" v-if="infoList.userInfo?.userAge">
                                    <div class="sleep_line_item"
                                        :style="{ width: `${infoList.userInfo.userAge ? infoList.userInfo.userAge : 0}%` }">
                                    </div>
                                </div>
                            </div>
                            <div class="sleep_list1">
                                <div class="sleep_text">
                                    <div class="sleep_text_left">
                                        生理年龄
                                    </div>
                                    <div class="sleep_text_right" v-if="infoList.userInfo?.theAge">
                                        {{ infoList.userInfo.theAge }}岁
                                    </div>
                                </div>
                                <div class="sleep_line" v-if="infoList.userInfo?.theAge">
                                    <div class="sleep_line_item"
                                        :style="{ width: `${infoList.userInfo.theAge ? infoList.userInfo.theAge : 0}%` }">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="achievement_pages_left_top_rigth_Proportion">
                            <div class="last_proportion" v-if="infoList.lastUserInfo && infoList.lastUserInfo.theAge">
                                <div style="display: flex;">
                                    <span>{{ infoList.lastUserInfo.theAge }}（{{ infoList.lastUserInfo.physiologyRate
                                        }}%）</span>
                                    <template v-if="infoList.lastUserInfo.type == 1">
                                        <img style="width: 1vmax;height: 1vmax;"
                                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                                    </template>
                                    <template v-if="infoList.lastUserInfo.type == 2">
                                        <img style="width: 1vmax;height: 1vmax;"
                                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                                    </template>
                                </div>
                                <div style="line-height: 2vmax;">与上次访问相比的变化</div>
                                <div>截至 {{ infoList.lastUserInfo.updateTime }}</div>
                            </div>
                            <div class="last_proportion" v-if="infoList.userInfo && infoList.userInfo.theAge">
                                <div style="display: flex;">
                                    <span>{{ infoList.userInfo.theAge }}（{{ infoList.userInfo.physiologyRate }}%）</span>
                                    <template v-if="infoList.userInfo.type == 1">
                                        <img style="width: 1vmax;height: 1vmax;"
                                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                                    </template>
                                    <template v-if="infoList.userInfo.type == 2">
                                        <img style="width: 1vmax;height: 1vmax;"
                                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                                    </template>
                                </div>
                                <div style="line-height: 2vmax;">
                                    超过{{ infoList.userInfo.physiologyRate }}%同龄人
                                </div>
                                <div>截至 {{ infoList.userInfo.updateTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="achievement_pages_left_bottom">
                <div class="tree">
                    <div class="treeText1">
                        <div v-for="(item, index) in listData" :key="index">
                            <!-- 第一级菜单 -->
                            <div class="treeText1_list" @click.stop="handleTree1(item, index)">
                                <div class="treeText1_arrow">
                                    <template v-if="item.check">
                                        <img class="wh100"
                                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240705/5B2F7B33339E4760B6D2EA9D7828B10F.png"
                                            alt="">
                                    </template>
                                    <template v-else>
                                        <img class="wh100"
                                            src="https://image.giantgocloud.com/www/ImageMapping/image/20240705/F9EA5D2A712B4FC28DDEF467884FA308.png"
                                            alt="">
                                    </template>
                                </div>
                                <div class="treeText1_sub" :style="{ backgroundColor: item.colorCode }">
                                    {{ item.colorLevel }}
                                </div>
                                <div :style="item.colorLevel ? '' : 'color:#969696'" class="treeText1_text">
                                    {{ item.organName }}
                                </div>
                            </div>
                            <!-- 第二级菜单 -->
                            <div v-if="item.check" v-for="(item1, index1) in item.indicators" :key="index1">
                                <div style="margin-left: 3.5vmax;" class="treeText1_list"
                                    @click.stop="handleTree2(item, index, item1, index1)" :key="index1">
                                    <!-- <div v-if="item1.colorLevel" class="treeText1_arrow">
                                        <template v-if="item1.check">
                                            <img class="wh100"
                                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240705/5B2F7B33339E4760B6D2EA9D7828B10F.png"
                                                alt="">
                                        </template>
                                        <template v-else>
                                            <img class="wh100"
                                                src="https://image.giantgocloud.com/www/ImageMapping/image/20240705/F9EA5D2A712B4FC28DDEF467884FA308.png"
                                                alt="">
                                        </template>
                                    </div>  -->
                                    <div class="treeText1_sub" :style="{ backgroundColor: item1.colorCode }">
                                        {{ item1.colorLevel }}
                                    </div>
                                    <div :style="item1.colorLevel ? '' : 'color:#969696'" class="treeText1_text">
                                        {{ item1.indicatorName }}
                                    </div>
                                    <div v-if="item1.colorLevel" style="display: flex; font-size: 1vmax;">
                                        <div style="padding-left: 1vmax;"> {{ item1.indicatorValue }}({{
                                            item1.indicatorUnit }})</div>
                                        <div style="padding-left: 2vmax;"> {{ item1.referenceValue }}</div>
                                    </div>
                                </div>
                                <!-- 第三级菜单 -->
                                <!-- <template v-if="item1.check">
                                    <div style="margin-left: 5.5vmax;justify-content: space-between;"
                                        class="treeText1_list" v-for="(item2, index2) in item1.indicators"
                                        @click.stop="handleTree3(item, index, item1, index1, item2, index2)"
                                        :key="index2">
                                        <div style="display: flex;">
                                            <div class="treeText1_sub" :style="{ backgroundColor: item2.colorCode }">
                                                {{ item2.colorLevel }}
                                            </div>
                                            <div :style="item2.colorLevel?'':'color:#969696'" class="treeText1_text">
                                                {{ item2.indicatorName }}
                                            </div>
                                        </div>
                                        <div style="display: flex; font-size: 1.2vmax;">
                                            <div> {{ item2.indicatorValue }}({{ item2.indicatorUnit }})</div>
                                            <div> {{ item2.referenceValue }}</div>
                                        </div>
                                    </div>
                                </template> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="achievement_pages_right">
            <template v-if="ifShowR">
                <Heart :selectionParames="selectionParames" />
                <template v-if="showCurentIndex == 0 && rightTabData?.indicatorsDescription">
                    <div class="physiological">
                        <div class="heart_title">
                            <div class="round"></div>
                            <div class="info">指标描述</div>
                        </div>
                        <div class="indicatorsDescription">
                            {{ rightTabData.indicatorsDescription }}
                        </div>
                    </div>
                </template>
                <template v-if="selectionParames.type == 3 && tabContentList">
                    <div class="tab" v-if="ifShowR">
                        <TabS :tabContentList="tabContentList" />
                    </div>
                </template>
            </template>
            <template v-if="isShowAchievement">
                <div class="PersonHome_left_title">
                    <div class="round"></div>
                    <div class="info">生物标志物介绍</div>
                </div>
                <div class="achievement_title">生物标志物：最科学准确的健康衡量工具</div>
                <div class="achievement_content">
                    想象一下，您处于身体的最佳状态——心脏强健、肌肉有力、体重理想，并且拥有充沛的能量和对身体的完全掌控感。问题是，如何实现这一目标？要采取哪些具体步骤才能达到这个理想的健康状态？
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    大多数人并不知道答案，前行的路被繁琐的医生建议和大量误导性的健康信息所掩盖。你该吃更多的蛋白质还是增加蔬菜摄入？要多做有氧运动还是举重？什么营养补充剂最适合你？面对这些问题，很多人都感到迷茫，最终放弃了健康目标，陷入迷雾中。
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    这就是生物标志物的重要性。它能为您扫清前进的障碍，精准指导，让您对实现健康目标充满信心。通过分析身体内部的各种数据，生物标志物提供了清晰的方向和个性化建议，帮助您制定科学可行的健康计划。
                </div>
                <div class="achievement_title">不仅是“正常”或“异常”，而是“最佳状态”</div>
                <div class="achievement_content">
                    您可能已经接受过医生的血液检测，结果通常会显示胆固醇、甘油三酯等生物标志物，之后您会被告知这些指标是“正常”还是“异常”。但这样的判断并不足够。我们相信，通过关注次优状态的生物标志物，您可以在问题变得严重之前主动进行改善。
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    因此，我们的科学家参考了大量的科学文献，研究了健康人群的生物标志物值，并根据年龄、性别、活动水平等因素，确定了个性化的最佳区域。这些区域比单纯的“临床正常”更具针对性。
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    当您收到生物标志物检测结果时，每个生物标志物的数值会在图表中显示，并划分为不同区域：
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    • 风险区（红色）：如果某个指标超出临床参考范围，建议与医疗专业人员联系，采取相应措施。
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    • 正常区（黄色）：这是大多数人群的“临床正常”区，但不一定反映您的个体最佳状态。
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    • 最佳区（绿色）：我们进一步根据您的个性化数据，创建了一个最佳范围，让您在健康上达到理想水平，力求达到的健康目标。
                </div>
                <div class="achievement_content" style="padding-top: 1vmax;">
                    通过这些精确的生物标志物分析，您不仅可以看到自己是否健康，还能了解如何从“正常”迈向“最佳”。
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import Heart from '/src/views/userInformation/components/heart.vue'
import Tab from '/src/views/userInformation/components/Tab.vue'
import TabS from '/src/views/userInformation/components/TabS.vue'
import {
    GetOrganSickTrend,
    GetSuggestionsByIndicators,
    GetUserOrganHealingInfo
} from '@/api/index'
export default {
    components: {
        Heart,
        Tab,
        TabS
    },
    data() {
        return {
            showCurentIndex: 0,
            ifShowR: false,
            isShowAchievement:true,
            infoList: [],
            listData: [],
            currentOrgin: '',
            rightTabData: null,
            tabContentList: null,
            tabList: [
                {
                    id: 0,
                    title: '指标信息'
                }
            ],
            selectionParames: {
                type: null,
                organId: null,
                indicatorsDescription: null,
                indicatorId: null,
                organName: null,
                colorLevel: null,
                colorCode: null,
                calculateDescribe: null,
                organIndicators: null
            }
        }
    },
    props: {

    },
    created() {

    },
    mounted() {
        this.GetUserOrganHealingInfoF()
        this.getTree()
    },
    methods: {
        GetUserOrganHealingInfoF() {
            const customerCode = this.$query('params')
            const parames = {
                customerCode
            }
            GetUserOrganHealingInfo(parames).then(res => {
                console.log('列表数据返回值', res.data)
                this.infoList = res.data
                console.log('this.listData ', this.listData)
            })

        },
        tabChange(index) {
            this.showCurentIndex = index
            console.log('currentIndex', index);
        },
        async getTree() {
            const customerCode = this.$query('params')
            const { data } = await GetOrganSickTrend({ customerCode })
            this.listData = data
            this.selectionParames.organId = data[0].organId
            this.selectionParames.organName = data[0].organName
            this.selectionParames.colorCode = data[0].colorCode
            this.selectionParames.colorLevel = data[0].colorLevel
            this.selectionParames.type = 1
            this.ifShowR = true
            console.log('data', data)
        },
        handleTree1(item, index) {
            this.listData[index].check = !this.listData[index].check
            this.$forceUpdate()
            if (!item.colorLevel) {
                return
            }
            this.isShowAchievement = false
            console.log('ite22222222222222m', item);
            this.selectionParames.type = 1
            this.selectionParames.organId = item.organId
            this.selectionParames.sickId = null
            this.selectionParames.indicatorId = null
            this.selectionParames.organName = item.organName
            this.selectionParames.colorCode = item.colorCode
            this.selectionParames.colorLevel = item.colorLevel
            this.selectionParames.calculateDescribe = item.calculateDescribe
            if (item.indicators && item.indicators.length > 0) {
                this.selectionParames.organIndicators = item.indicators
            } else {
                this.selectionParames.organIndicators = null
            }

            console.log('this.selectionParames', this.selectionParames.organIndicators);
            this.ifShowR = true
        },
        async handleTree2(item, index, item1, index1) {
            if (!item1.colorLevel) {
                return
            }
            const customerCode = this.$query('params')
            let { data } = await GetSuggestionsByIndicators({ indicatorId: item1.indicatorId, customerCode })
            this.tabContentList = data
            console.log('tabContentList', this.tabContentList);
            this.ifShowR = true
            this.selectionParames.type = 3
            this.selectionParames.organId = item.organId
            this.selectionParames.indicatorId = item1.indicatorId
            this.selectionParames.sickId = item1.indicatorId
            this.selectionParames.indicatorsDescription = item1.indicatorsDescription
            this.selectionParames.organName = item1.indicatorName
            this.selectionParames.colorCode = item1.colorCode
            this.selectionParames.colorLevel = item1.colorLevel
            this.rightTabData = item1
            this.selectionParames.calculateDescribe = null
            this.selectionParames.organIndicators = null
            this.$forceUpdate()

        },
        handleTree3(item, index, item1, index1, item2, index2) {
            if (!item2.colorLevel) {
                return
            }
            this.selectionParames.type = 3
            this.selectionParames.organId = item.organId
            this.selectionParames.sickId = item1.sickId
            this.selectionParames.indicatorId = item2.indicatorId
            this.selectionParames.organName = item2.indicatorName
            this.ifShowR = true
        }
    }
}
</script>
<style lang='scss' scoped>
.achievement_pages {
    display: flex;

    .PersonHome_left_title {
        display: flex;
        align-items: center;
        // padding-top: 1.8vmax;

        .round {
            width: 0.8vmax;
            height: 0.8vmax;
            background: #18BFC8;
            border-radius: 50%;
        }

        .info {
            margin-left: 1vmax;
            padding: 0.2vmax 0.6vmax;
            background: #20D0D9;
            border-radius: 0.5vmax;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            font-size: 1.6vmax;
            color: #FFFFFF;
            text-align: left;
            font-style: normal;
            text-transform: none;
            padding-left: 0.6vmax;
        }
    }

    .achievement_title {
        font-size: 1.4vmax;
        font-weight: 600;
        padding: 1vmax 0;
        padding-left: 2vmax;
    }

    .achievement_content {
        font-size: 1.2vmax;
        padding-left: 2vmax;
        padding-right: 1vmax;
    }

    .achievement_pages_title {
        position: relative;
        padding-left: 1.8em;
        font-size: 1.5vmax;
        color: #FFFFFF;
    }

    .achievement_pages_title::before {
        content: "";
        width: 0.8vmax;
        height: 0.8vmax;
        position: absolute;
        left: 1vmax;
        top: 0.7vmax;
        border-radius: 50%;
        background-color: #18BFC8;
    }

    .achievement_pages_left {
        margin-left: 1vmax;

        .achievement_pages_left_top {
            display: flex;
            box-sizing: border-box;
            padding: 1.3vmax 1.5vmax;
            width: 38vmax;
            height: 14vmax;
            background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
            border-radius: 1vmax;

            .transcript {
                display: flex;
                justify-content: space-between;
            }

            .achievement_pages_left_top_left {

                .achievement_pages_left_top_left_title {
                    font-size: 1.6vmax;
                    color: #FFFFFF;
                    line-height: 1.9vmax;
                    text-align: left;
                    font-style: normal;
                }

                .achievement_pages_left_top_left_time {
                    font-weight: 400;
                    font-size: 0.8vmax;
                    color: rgba(255, 255, 255, 0.6);
                    line-height: 0.9vmax;
                    text-align: left;
                    font-style: normal;
                }

                .result {
                    margin-top: 2vmax;
                    width: 6vmax;
                    height: 6vmax;
                }

                .sleep {
                    padding-left: 3vmax;
                    padding-top: 1vmax;

                    .sleep_list {
                        padding-top: 1vmax;

                        .sleep_text {
                            display: flex;
                            justify-content: space-between;

                            .sleep_text_left {
                                color: #FFFFFF;
                            }

                            .sleep_text_right {
                                color: #F9C716;
                            }
                        }

                        .sleep_line {
                            width: 10vmax;
                            height: 0.4vmax;
                            margin-top: 0.4vmax;
                            background: #D9D9D9;
                            border-radius: 1.4vmax;
                            overflow: hidden;

                            .sleep_line_item {
                                height: 0.4vmax;
                                background-color: #F9C716;
                            }
                        }
                    }

                    .sleep_list1 {
                        padding-top: 1vmax;

                        .sleep_text {
                            display: flex;
                            justify-content: space-between;

                            .sleep_text_left {
                                color: #FFFFFF;
                            }

                            .sleep_text_right {
                                color: #5EC692;
                            }
                        }

                        .sleep_line {
                            width: 10vmax;
                            height: 0.4vmax;
                            margin-top: 0.4vmax;
                            background: #D9D9D9;
                            border-radius: 1.4vmax;
                            overflow: hidden;

                            .sleep_line_item {
                                height: 0.4vmax;
                                background-color: #5EC692;
                            }
                        }
                    }
                }

                .achievement_pages_left_top_rigth_Proportion {
                    display: flex;
                    padding-top: 2vmax;
                    padding-left: 1vmax;

                    .last_proportion {
                        font-size: 1vmax;
                        padding-left: 3vmax;
                        padding-bottom: 3vmax;
                    }
                }
            }
        }

        .achievement_pages_left_bottom {
            margin-top: 1vmax;
            box-sizing: border-box;
            padding: 1.5vmax;
            width: 38vmax;
            height: 44.4vmax;
            background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
            border-radius: 1vmax;
            overflow-y: scroll;

            .tree {
                font-size: 1.4vmax;
                font-weight: 600;

                .treeText1 {
                    .treeText1_list {
                        display: flex;
                        align-items: center;
                        border-bottom: 1px solid #38394D;
                        padding-bottom: 2vmax;
                        padding-top: 1.2vmax;

                        .treeText1_arrow {
                            width: 1vmax;
                            height: 1vmax;
                        }

                        .treeText1_sub {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 2vmax;
                            height: 2vmax;
                            border-radius: 0.6vmax;
                            margin-left: 1.4vmax
                        }

                        .treeText1_text {
                            padding-left: 1vmax;
                        }
                    }
                }
            }
        }
    }

    .achievement_pages_right {
        overflow-y: auto;
        box-sizing: border-box;
        padding: 1.5vmax;
        padding-right: 0;
        margin-left: 1vmax;
        width: 51vmax;
        height: 59.4vmax;
        background: #2B2C3C;
        border-radius: 1vmax;

        .physiological {
            padding-left: 1vmax;
            color: #FFFFFF;
            font-size: 1.4vmax;
            padding-top: 1vmax;

            .heart_title {
                display: flex;
                align-items: center;

                .round {
                    width: 0.8vmax;
                    height: 0.8vmax;
                    background: #18BFC8;
                    border-radius: 50%;
                }

                .info {
                    font-family: PingFang SC, PingFang SC;
                    font-weight: 600;
                    font-size: 1.5vmax;
                    color: #FFFFFF;
                    text-align: left;
                    font-style: normal;
                    text-transform: none;
                    padding-left: 0.6vmax;
                }
            }

            .indicatorsDescription {
                padding-top: 1vmax;
                padding-right: 2vmax;
                font-size: 1.2vmax;
            }
        }
    }
}
</style>
