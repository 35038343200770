<template>
  <div class="achievement_pages">
    <div class="achievement_pages_left">
      <div class="achievement_pages_left_top">
        <div class="achievement_pages_left_top_left">
          <div class="achievement_pages_left_top_left_title" @click="handleSwicth">
            主题看板
          </div>
          <div class="achievement_pages_left_top_left_time">
            截至 2023-03-22
          </div>
          <div class="transcript">
            <div class="result">
              <img
                src="https://image.giantgocloud.com/www/ImageMapping/image/20240819/899383F709A34B67A972DDA573BB7C51.png"
                class="wh100">
            </div>
            <div class="sleep">
              <div class="sleep_list">
                <div class="sleep_text">
                  <div class="sleep_text_left">
                    真实年龄
                  </div>
                  <div class="sleep_text_right" v-if="listData.userInfo?.userAge">
                    {{ listData.userInfo.userAge }}岁
                  </div>
                </div>
                <div class="sleep_line" v-if="listData.userInfo?.userAge">
                  <div class="sleep_line_item"
                    :style="{ width: `${listData.userInfo.userAge ? listData.userInfo.userAge : 0}%` }"></div>
                </div>
              </div>
              <div class="sleep_list1">
                <div class="sleep_text">
                  <div class="sleep_text_left">
                    生理年龄
                  </div>
                  <div class="sleep_text_right" v-if="listData.userInfo?.theAge">
                    {{ listData.userInfo.theAge }}岁
                  </div>
                </div>
                <div class="sleep_line" v-if="listData.userInfo?.theAge">
                  <div class="sleep_line_item"
                    :style="{ width: `${listData.userInfo.theAge ? listData.userInfo.theAge : 0}%` }"></div>
                </div>
              </div>
            </div>
            <div class="achievement_pages_left_top_rigth_Proportion">
              <div class="last_proportion" v-if="listData.lastUserInfo && listData.lastUserInfo.theAge">
                <div style="display: flex;">
                  <span>{{ listData.lastUserInfo.theAge }}（{{ listData.lastUserInfo.physiologyRate }}%）</span>
                  <template v-if="listData.lastUserInfo.type == 1">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                  </template>
                  <template v-if="listData.lastUserInfo.type == 2">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                  </template>
                </div>
                <div style="line-height: 2vmax;">与上次访问相比的变化</div>
                <div>截至 {{ listData.lastUserInfo.updateTime }}</div>
              </div>
              <div class="last_proportion" v-if="listData.userInfo && listData.userInfo.theAge">
                <div style="display: flex;">
                  <span>{{ listData.userInfo.theAge }}（{{ listData.userInfo.physiologyRate }}%）</span>
                  <template v-if="listData.userInfo.type == 1">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/99D1ED9B254549A2A6B9ACA5DA9D3407.png">
                  </template>
                  <template v-if="listData.userInfo.type == 2">
                    <img style="width: 1vmax;height: 1vmax;"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240827/F9D451D45C264D8CAE19705759BC5F47.png">
                  </template>
                </div>
                <div style="line-height: 2vmax;">
                  超过{{ listData.userInfo.physiologyRate }}%同龄人
                </div>
                <div>截至 {{ listData.userInfo.updateTime }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="achievement_pages_left_bottom">
        <div class="achievement_pages_left_bottom_table">
          <div class="achievement_pages_left_bottom_table_tab">
            <div class="achievement_pages_left_bottom_table_title" style="width: 4vmax;flex: none;">等级</div>
            <div class="achievement_pages_left_bottom_table_title" style="width: 11vmax;flex: none;">主题名称</div>
            <div class="achievement_pages_left_bottom_table_title" style="width: 11vmax;flex: none;">上次结果</div>
            <div class="achievement_pages_left_bottom_table_title" style="width: 11vmax;flex: none;">与上次比</div>
          </div>
          <div class="achievement_pages_left_bottom_table_content">
            <div v-for="(item, index) in listData.organList" :key="index" @click="clickListData(item)"
              class="achievement_pages_left_bottom_table_content_li">
              <div class="achievement_pages_left_bottom_table_content_li_h"></div>
              <div class="achievement_pages_left_bottom_table_content_li_ul">
                <div class="achievement_pages_left_bottom_table_content_li_ul_li a_flex"
                  style="width: 3vmax;flex: none;">
                  <div :style="'background:' + item.levelCode"
                    class="achievement_pages_left_bottom_table_content_li_ul_li_level a_flex">
                    {{ item.level }}
                  </div>
                </div>
                <div class="achievement_pages_left_bottom_table_content_li_ul_li a_flex"
                  style="width: 11vmax;flex: none;">
                  {{ item.organName }}
                </div>
                <div :style="'color:' + item.lastLevelCode"
                  class="achievement_pages_left_bottom_table_content_li_ul_li a_flex" style="width: 11vmax;flex: none;">
                  {{ item.lastLevel }}
                </div>
                <div class="achievement_pages_left_bottom_table_content_li_ul_li a_flex"
                  style="width: 11vmax;flex: none;">
                  <div>
                    <img v-if="item.isPromote == 1"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240704/079E00908F414947B2F802CCF0EFE780.png" />

                    <img v-if="item.isPromote == 2"
                      src="https://image.giantgocloud.com/www/ImageMapping/image/20240704/3727FA2C8709407DBD80B53468A0CF40.png" />
                  </div>
                  <div v-if="item.isPromote == 0" class="achievement_pages_left_bottom_table_content_li_ul_li_upD">
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="achievement_pages_right">
      <div v-if="ifShowR" style="margin-right: 2.5vmax;">
        <Heart :selectionParames="currentOrgin" />
      </div>
      <div v-if="!ifShowR">
        <div class="PersonHome_left_title">
          <div class="round"></div>
          <div class="info">全身器官健康概览图</div>
        </div>
        <div id="barChart" style="width: 100%;height:30vmax;"></div>
        <template v-if="isOrgin">
          <div class="PersonHome_left_title">
            <div class="round"></div>
            <div class="info">器官功能详情</div>
          </div>
          <div class="achievement_pages_right_ul" v-if="origiDetails">
            <div class="origin">
              {{ origiDetails.organName }}：<span :style="{ color: origiDetails.colorCode, fontSize: '1.4vmax' }">{{
                origiDetails.organLevel
              }}</span>
            </div>
            <!-- <div class="exceed">
            超过 <span style="font-size: 1.6vmax;">{{ origiDetails.rankValue }}</span> 同龄人
          </div> -->
            <div class="description">
              <div class="description_title">
                {{ origiDetails.title }}
              </div>
              <div class="description_content">
                {{ origiDetails.description }}
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="PersonHome_left_title">
            <div class="round"></div>
            <div class="info">什么是健康寿命类别？</div>
          </div>
          <div class="achievement_title">健康寿命类别：解锁您的健康</div>
          <div class="achievement_content">
            “寿命”衡量您活多久，而“健康寿命”则关注您在没有慢性病或年龄相关疾病的情况下的健康生活年数。我们重视健康寿命，旨在帮助您增加健康年数，让您今天就感到更好，并提高长期的日常活动能力。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            为此，我们将您的身体数据——来自血液检测和健身追踪器——分为16个“健康寿命类别评分”。这些评分涵盖代谢、心脏健康、睡眠和认知等关键领域，以A到F的评分量化您的健康状况。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            每个评分为您提供关于身体表现的背景信息，指引您努力的方向。随着您在平台上添加更多数据（如血液检测和健身追踪），您的健康寿命类别评分将持续反映您当前的健康状态。这些分数是我们平台的关键功能，独特且无与伦比，能够展示您的血液生物标志物如何影响健康寿命。
          </div>
          <div class="achievement_title">  我们为什么要创建健康寿命类别分数？</div>
          <div class="achievement_content">
            我们的使命是简化复杂的健康建议，让会员掌控自己的健康。虽然数据有帮助，但也可能让人感到信息过载。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            健康寿命类别使数据更易于理解，将大量信息压缩为16个简单的分数，完整讲述您的健康故事。
          </div>
          <div class="achievement_title">  这些分数的优势：</div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            • 实用性：健康寿命类别帮助您理解生物标志物与生活的关系。例如，了解低铁如何影响耐力的知识有助于促进行为改变。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            • 准确性：身体是一个复杂的系统，健康寿命类别整合血液生物标志物与生理标志物，展示各系统间的相互作用。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            • 全面影响：生物标志物往往并非单一作用。例如，压力荷尔蒙皮质醇影响荷尔蒙平衡、认知、肠道健康等多个方面。
          </div>
          <div class="achievement_title">  健康寿命类别分数如何计算？</div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            • 标记加权：生物标志物根据对类别的影响进行加权，依据我们与美国科学家的研究成果。例如，血糖对代谢的影响大于总胆固醇。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            • 区域贡献：每个分数根据生物标志物与“最佳”区域的接近程度加权。标记被分为最佳、正常、高或低，且根据您的年龄、性别和活动水平个性化设置。
          </div>
          <div class="achievement_content" style="padding-top: 1vmax;">
            • 需最新结果：所有健康寿命类别分数基于您最近的生物标志物结果计算。如果缺少必要标记，评分将使用90天内的旧结果替代。
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import Heart from '/src/views/userInformation/components/heart.vue'
import {
  GetUserOrganHealingInfo, GetUserOrganHealingChartData
} from '@/api/index'
export default {
  components: {
    Heart
  },
  data() {
    return {
      isOrgin: false,
      ifShowR: false,
      listData: [],
      currentOrgin: {
        type: null,
        organId: null,
        organName: null,
        colorLevel: null,
        colorCode: null,
        calculateDescribe: null,
        organIndicators: null
      },
      origiDetails: ''
    }
  },
  props: {

  },
  created() {

  },
  mounted() {
    this.GetUserOrganHealingInfoF()
    this.GetUserOrganHealingChartDataF()
  },
  methods: {
    handleSwicth() {
      this.GetUserOrganHealingChartDataF()
      this.ifShowR = false
    },
    clickListData(item) {
      if (!item.level) return
      this.ifShowR = true
      this.currentOrgin.organId = item.organId
      this.currentOrgin.organName = item.organName
      this.currentOrgin.colorLevel = item.level
      this.currentOrgin.colorCode = item.levelCode
      this.currentOrgin.type = 1
      this.currentOrgin.calculateDescribe = item.calculateDescribe
      this.currentOrgin.organIndicators = item.organIndicators
    },
    GetUserOrganHealingChartDataF() {
      const customerCode = this.$query('params')
      const parames = {
        customerCode
      }
      GetUserOrganHealingChartData(parames).then(res => {
        console.log('柱状图数据返回值', res.data)
        if (res.data && res.data.length > 0) {
          this.origiDetails = res.data[0]
        }
        this.drawChart(res.data)
      })

    },
    GetUserOrganHealingInfoF() {
      const customerCode = this.$query('params')
      const parames = {
        customerCode
      }
      GetUserOrganHealingInfo(parames).then(res => {
        console.log('列表数据返回值', res.data)
        this.listData = res.data
        console.log('this.listData ', this.listData)
      })

    },
    updateData(data) {
      this.origiDetails = data
    },
    drawChart(data) {
      var myChart = this.$echarts.init(document.getElementById('barChart'))
      console.log('data', data);
      let seriesData = []
      let xAxisData = []
      data.forEach(item => {
        seriesData.push(
          {
            value: item.value,
            itemStyle: { color: item.colorCode },
            details: item
          }
        )
        xAxisData.push(item.organName)
      })
      const option = {
        // dataZoom: [
        //   {
        //     type: "inside",
        //     startValue: 0,
        //     endValue: 9,
        //   },
        // ],
        grid: {
          left: "3%", //相当于距离左边效果:padding-left
          top: "8%", //相当于距离上边效果:padding-top
          right: "5%",
          bottom: "3%",
          containLabel: true, //防止标签溢出
        },
        legend: { // 图例组件
          show: false, // 设置不显示图例 
        },
        xAxis: {
          type: 'category',
          data: xAxisData,
          axisTick: {
            show: false // 是否显示刻度线
          },
          axisLabel: {
            interval: 0,
            rotate: -45,  //倾斜的程度
            // color: function (value, index) {
            //   // 根据 series 数据返回不同的颜色
            //   let seriesValue = option.series[0].data[index]
            //   let color
            //   switch (seriesValue.value) {
            //     case 0:
            //       color = '#969799';
            //       break
            //     case 1:
            //       color = '#10854A';
            //       break
            //     case 2:
            //       color = '#5EC692';
            //       break
            //     case 3:
            //       color = '#F9C716';
            //       break
            //     case 4:
            //       color = '#FE7283';
            //       break
            //     case 5:
            //       color = '#D51931';
            //       break
            //     default:
            //       color = '#969799';
            //   }
            //   return color
            // }
          }
        },
        yAxis: {
          max: 5,
          axisLabel: {
            show: true, // 是否显示刻度标签
            formatter: function (value) {
              const statusMap = {
                1: '最佳',
                2: '正常',
                3: '风险',
                4: '疾病',
                5: '严重'
              }
              return statusMap[value]
            },
            // textStyle: {
            //   color: '#ffffff99' // 设置标签文字的颜色  
            // }
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(159,174,208,0.1)'
            }
          }
        },
        series: [{
          tooltip: {
            trigger: 'none' // 设置触发方式为'none'，点击时不显示tooltip
          },
          barWidth: '22%',
          type: 'bar',
          data: seriesData,
        }
        ]
      }
      myChart.setOption(option)
      // 处理点击
      let that = this
      myChart.on('click', function (params) {
        that.isOrgin = true
        console.log('params', params);
        console.log('paramscolorcolorcolor', params.data.details.colorCode);
        const newData = params.data.details;
        that.updateData(newData);
        // if (params.componentType === 'series') {
        //   let dataIndex = params.dataIndex;
        //   let series = option.series[0];
        //   let itemStyle = series.data[dataIndex].itemStyle || {};
        //   // 设置点击后柱子的样式
        //   series.data[dataIndex].itemStyle = {
        //     normal: {
        //       borderWidth: 5,
        //       borderColor: params.data.details.colorCode
        //     }
        //   };
        //   // 更新图表
        //   myChart.setOption(option);
        // }
      })
    }
  },
  watch: {

  },
  computed: {

  }
}
</script>
<style lang='scss' scoped>
.achievement_pages {
  display: flex;

  .PersonHome_left_title {
    display: flex;
    align-items: center;
    // padding-top: 1.8vmax;

    .round {
      width: 0.8vmax;
      height: 0.8vmax;
      background: #18BFC8;
      border-radius: 50%;
    }

    .info {
      margin-left: 1vmax;
      padding: 0.2vmax 0.6vmax;
      background: #20D0D9;
      border-radius: 0.5vmax;
      font-family: PingFang SC, PingFang SC;
      font-weight: 600;
      font-size: 1.6vmax;
      color: #FFFFFF;
      text-align: left;
      font-style: normal;
      text-transform: none;
      padding-left: 0.6vmax;
    }
  }

  .achievement_title {
    font-size: 1.4vmax;
    font-weight: 600;
    padding: 1vmax 0;
    padding-left: 2vmax;
  }

  .achievement_content {
    font-size: 1.2vmax;
    padding-left: 2vmax;
    padding-right: 1vmax;
  }

  .achievement_pages_title {
    position: relative;
    padding-left: 1.8em;
    font-size: 1.5vmax;
    color: #FFFFFF;
  }

  // .achievement_pages_title {
  //   position: relative;
  //   padding-left: 1.8em;
  //   font-size: 1.5vmax;
  //   color: #FFFFFF;
  //   background: #20D0D9;
  // }

  // .achievement_pages_title::before {
  //   content: ""; 
  //   width: 0.8vmax;
  //   height: 0.8vmax;
  //   position: absolute;
  //   left: 1vmax;
  //   top: 0.7vmax;
  //   border-radius: 50%;
  //   background-color: #18BFC8;
  // }
  .achievement {
    padding: 1vmax 2vmax;
    font-size: 1.4vmax;
  }

  .achievement_pages_left {
    margin-left: 1vmax;

    .achievement_pages_left_top {
      display: flex;
      box-sizing: border-box;
      padding: 1.3vmax 1.5vmax;
      width: 41vmax;
      height: 14vmax;
      background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
      border-radius: 1vmax;

      .transcript {
        display: flex;
        justify-content: space-between;
      }

      .achievement_pages_left_top_left {

        .achievement_pages_left_top_left_title {
          font-size: 1.6vmax;
          color: #FFFFFF;
          line-height: 1.9vmax;
          text-align: left;
          font-style: normal;
        }

        .achievement_pages_left_top_left_time {
          font-weight: 400;
          font-size: 0.8vmax;
          color: rgba(255, 255, 255, 0.6);
          line-height: 0.9vmax;
          text-align: left;
          font-style: normal;
        }

        .result {
          margin-top: 2vmax;
          width: 6vmax;
          height: 6vmax;
        }

        .sleep {
          padding-left: 3vmax;
          padding-top: 1vmax;

          .sleep_list {
            padding-top: 1vmax;

            .sleep_text {
              display: flex;
              justify-content: space-between;

              .sleep_text_left {
                color: #FFFFFF;
              }

              .sleep_text_right {
                color: #F9C716;
              }
            }

            .sleep_line {
              width: 10vmax;
              height: 0.4vmax;
              margin-top: 0.4vmax;
              background: #D9D9D9;
              border-radius: 1.4vmax;
              overflow: hidden;

              .sleep_line_item {
                height: 0.4vmax;
                background-color: #F9C716;
              }
            }
          }

          .sleep_list1 {
            padding-top: 1vmax;

            .sleep_text {
              display: flex;
              justify-content: space-between;

              .sleep_text_left {
                color: #FFFFFF;
              }

              .sleep_text_right {
                color: #5EC692;
              }
            }

            .sleep_line {
              width: 10vmax;
              height: 0.4vmax;
              margin-top: 0.4vmax;
              background: #D9D9D9;
              border-radius: 1.4vmax;
              overflow: hidden;

              .sleep_line_item {
                height: 0.4vmax;
                background-color: #5EC692;
              }
            }
          }
        }

        .achievement_pages_left_top_rigth_Proportion {
          display: flex;
          padding-top: 2vmax;
          padding-left: 1vmax;

          .last_proportion {
            font-size: 1vmax;
            padding-left: 3vmax;
            padding-bottom: 3vmax;
          }
        }
      }
    }

    .achievement_pages_left_bottom {
      box-sizing: border-box;
      padding: 1.5vmax;
      margin-top: 1vmax;
      width: 41vmax;
      height: 44.4vmax;
      background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
      border-radius: 1vmax;

      .achievement_pages_left_bottom_table {
        margin-top: 1vmax;

        .achievement_pages_left_bottom_table_tab {
          box-sizing: border-box;
          padding: 0 1vmax;
          display: flex;
          justify-content: space-between;
          margin-bottom: 1vmax;

          .achievement_pages_left_bottom_table_title {
            display: flex;
            justify-content: center;
            flex: 1;
            font-weight: 400;
            font-size: 1.6vmax;
            color: rgba(255, 255, 255, 0.6);
          }
        }

        .achievement_pages_left_bottom_table_content {
          height: 30.7vmax;
          overflow-y: auto;

          .achievement_pages_left_bottom_table_content_li {
            .achievement_pages_left_bottom_table_content_li_h {
              width: 100%;
              width: 38vmax;
              height: 0.1vmax;
              background: rgba(159, 174, 208, 0.1);
            }

            .achievement_pages_left_bottom_table_content_li_ul {
              padding: 0 1vmax;
              display: flex;
              justify-content: space-between;
              margin: 1vmax 0;

              .achievement_pages_left_bottom_table_content_li_ul_li {
                text-align: center;
                font-size: 1.2vmax;
                color: #FFFFFF;

                img {
                  width: 0.7vmax;
                  height: 1.5vmax;
                }

                .achievement_pages_left_bottom_table_content_li_ul_li_upD {
                  width: 1.5vmax;
                  height: 0.3vmax;
                  background-color: #fff;
                }

                .achievement_pages_left_bottom_table_content_li_ul_li_level {
                  width: 2.5vmax;
                  height: 2.5vmax;
                  background: #969696;
                  border-radius: 0.6vmax;
                  font-weight: 500;
                  font-size: 1.6vmax;
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }

    }
  }

  .achievement_pages_right {
    overflow-y: auto;
    box-sizing: border-box;
    padding: 1.5vmax;
    padding-right: 0;
    margin-left: 1vmax;
    width: 48vmax;
    height: 59.4vmax;
    background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
    border-radius: 1vmax;

    .achievement_pages_right_ul {
      font-size: 1.2vmax;

      .origin {
        padding-left: 1vmax;
        padding-top: 0.6vmax;
      }

      .exceed {
        padding-left: 1vmax;
        padding-top: 0.6vmax;
      }

      .description {
        padding-left: 1vmax;
        padding-top: 1.8vmax;

        .description_title {
          font-size: 1.4vmax;
        }

        .description_content {
          padding-top: 0.8vmax;
          font-size: 1.2vmax;
          padding-right: 2vmax;
        }
      }
    }
  }
}
</style>
