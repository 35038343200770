<template>
    <div class="PersonHome" v-if="info">
        <div class="PersonHome_left">
            <div class="PersonHome_left_title" @click="changeBack">
                <div class="round"></div>
                <div class="info">个人信息</div>
            </div>
            <div class="PersonHome_left_content">
                <span class="text">姓名：{{ info.userInfo.userName }}</span>
                <span class="text">性别：{{ info.userInfo.sex }}</span>
                <span class="text">出生年月：{{ info.userInfo.birthday }}</span>
                <span class="text">身高：{{ info.userInfo.height }}</span>
                <span class="text">体重：{{ info.userInfo.weight }} </span>
            </div>
            <template v-if="userType != 3 && userType != 4">
                <div class="PersonHome_left_title">
                    <div class="round"></div>
                    <div class="info">家族史</div>
                </div>
                <template
                    v-if="info.familyData && info.familyData.length > 0 && info.familyData[0].balanceDetail.length > 0">
                    <div class="disease">
                        <div class="disease_list" :class="item.subUnBalanceTitle == Highlight ? 'acitive' : ''"
                            @click="handleHistory(1, item, index)"
                            v-for="(item, index) in info.familyData[0].balanceDetail">
                            <div class="disease_list_round"></div>
                            <div class="disease_list_text">{{ item.subUnBalanceTitle }}</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="imgs">
                        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240508/76C694D826E0407CA3DBA0028E726D5E.png"
                            class="wh100">
                    </div>
                </template>
                <div class="PersonHome_left_title">
                    <div class="round"></div>
                    <div class="info">既往史</div>
                </div>
                <template v-if="info.history && info.history.length > 0 && info.history[0].balanceDetail.length > 0">
                    <div class="disease">
                        <div class="disease_list" :class="item.subUnBalanceTitle == Highlight ? 'acitive' : ''"
                            @click="handleHistory(2, item, index)"
                            v-for="(item, index) in info.history[0].balanceDetail">
                            <div class="disease_list_round"></div>
                            <div class="disease_list_text">{{ item.subUnBalanceTitle }}</div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="imgs" style="width: 40.5vmax;">
                        <img src="https://image.giantgocloud.com/www/ImageMapping/image/20240508/6A833BB76BFC4521B864CEC0040BDAB8.png"
                            class="wh100">
                    </div>
                </template>
                <div class="PersonHome_left_title"
                    v-if="info.nowSicks && info.nowSicks.length > 0 && info.nowSicks[0].balanceDetail.length > 0">
                    <div class="round"></div>
                    <div class="info">现有健康症状及风险</div>
                </div>
                <template v-if="info.nowSicks && info.nowSicks.length > 0 && info.nowSicks[0].balanceDetail.length > 0">
                    <div class="disease">
                        <div class="disease_list"
                            :class="(item.subUnBalanceTitle == Highlight && currentIndexData == 3) ? 'acitive' : ''"
                            @click="handleHistory(3, item, index)"
                            v-for="(item, index) in info.nowSicks[0].balanceDetail">
                            <div class="disease_list_round"></div>
                            <div class="disease_list_text">{{ item.subUnBalanceTitle }}</div>
                        </div>
                    </div>
                </template>
                <template v-if="info.organList && info.organList.length > 0">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info">全身系统/器官健康等级分析</div>
                    </div>
                    <div class="disease">
                        <template v-for="(item, index) in info.organList">
                            <div class="disease_list" v-if="index < 8"
                                :class="(item.typeName == Highlight && currentIndexData == 1) ? 'acitive' : ''"
                                @click="handleOrganList(1, item, index)"
                                :style="`background:${hexToRgb(item.typeColorCode, 0.1)}; borderColor:${item.typeColorCode}`">
                                <div class="disease_list_round" :style="`background:${item.typeColorCode}`"></div>
                                <div class="disease_list_text">{{ item.typeName }}</div>
                            </div>
                        </template>
                    </div>
                    <div class="disease">
                        <template v-for="(item, index) in info.organList">
                            <div v-if="index > 7" class="disease_list"
                                :class="(item.typeName == Highlight && currentIndexData == 1) ? 'acitive' : ''"
                                @click="handleOrganList(1, item, index)"
                                :style="`background:${hexToRgb(item.typeColorCode, 0.1)}; borderColor:${item.typeColorCode}`">
                                <div class="disease_list_round" :style="`background:${item.typeColorCode}`"></div>
                                <div class="disease_list_text">{{ item.typeName }}</div>
                            </div>
                        </template>
                    </div>
                </template>
                <template v-if="info.unBalanceList && info.unBalanceList.length > 0">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info">失衡因素</div>
                    </div>
                    <div class="disease">
                        <div class="disease_list" :class="item.unBalanceTitle == Highlight ? 'acitive' : ''"
                            @click="handleOrganList(2, item, index)"
                            :style="`background:${hexToRgb(item.unBalanceColorCode, 0.1)}; borderColor:${item.unBalanceColorCode}`"
                            v-for="(item, index) in info.unBalanceList">
                            <div class="disease_list_round" :style="`background:${item.unBalanceColorCode}`"></div>
                            <div class="disease_list_text">{{ item.unBalanceTitle }}</div>
                        </div>
                    </div>
                </template>
                <template v-if="info.dietary.length > 0">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info">饮食结构</div>
                    </div>
                    <div class="disease">
                        <div class="disease_item" :class="item.lifeName == Highlight ? 'acitive' : ''"
                            @click="handleDiseaseItemClick(1, item, index)" v-for="(item, index) in info.dietary">
                            <div class="disease_item_left">
                                <div class="left_text">{{ item.lifeName }}</div>
                                <div class="left_icon">
                                    <img class="wh100"
                                        src="https://image.giantgocloud.com/www/ImageMapping/image/20240515/916A56DFBCCE4B9C85EA36FFAF06BF29.png"
                                        alt="">
                                </div>
                            </div>
                            <div class="disease_item_right">
                                <img class="wh100" :src="item.lifePictuteUrl" alt="">
                            </div>
                        </div>
                    </div>
                </template>
                <template v-if="info.lifeData.length > 0">
                    <div class="PersonHome_left_title">
                        <div class="round"></div>
                        <div class="info">生活方式</div>
                    </div>
                    <div class="disease">
                        <div class="disease_item" :class="item.lifeName == Highlight ? 'acitive' : ''"
                            @click="handleDiseaseItemClick(2, item, index)" v-for="(item, index) in info.lifeData">
                            <div class="disease_item_left">
                                <div class="left_text">{{ item.lifeName }}</div>
                                <div class="left_icon">
                                    <img class="wh100"
                                        src="https://image.giantgocloud.com/www/ImageMapping/image/20240515/916A56DFBCCE4B9C85EA36FFAF06BF29.png"
                                        alt="">
                                </div>
                            </div>
                            <div class="disease_item_right">
                                <img class="wh100" :src="item.lifePictuteUrl" alt="">
                            </div>
                        </div>
                    </div>
                </template>
            </template>
        </div>
        <div class="PersonHome_right">
            <template v-if="userType != 3 && userType != 4">
                <template v-if="isShowSource">
                    <div class="PersonHome_rgight_title" v-if="rightTitle">
                        <div class="round"></div>
                        <div class="info1" style="font-size: 1.6vmax">{{ rightTitle }}</div>
                    </div>
                    <template v-if="type == 1 || type == 2">
                        <div class="rightTitle_samll_title" v-if="smallTitle">
                            {{ smallTitle }}
                        </div>
                        <div class="PersonHome_right_content" v-for="(item, index) in rightContent">
                            {{ item }}
                        </div>
                    </template>
                    <template v-else-if="type == 4">
                        <div class="rightTitle_samll_title" v-if="smallTitle">
                            {{ smallTitle }}
                        </div>
                        <div class="PersonHome_right_content">
                            {{ rightContent }}
                        </div>
                    </template>
                    <template v-else>
                        <template v-if="indexList.questionAnswer.length > 0">
                            <div class="rightTitle_samll_title" v-if="indexList.questionAnswer?.length > 0">
                                问卷
                            </div>
                            <div class="PersonHome_right_content" v-for="(item, index) in indexList.questionAnswer">
                                {{ item }}
                            </div>
                        </template>
                        <template v-else>
                            <div class="rightTitle_samll_title" v-if="indexList.indicatorsList?.length > 0">
                                指标
                            </div>
                            <div class="PersonHome_right_content" v-for="(item, index) in indexList.indicatorsList">
                                {{ item.indicatorsName }}
                            </div>
                        </template>
                    </template>
                </template>
                <template v-else>
                    <GuideMap :GuideMapData="GuideMapData" />
                </template>
            </template>
        </div>
        <div class="model">
            <Model :ModalBase="ModalBase" @handleClose="handleClose" @changeInside="handleClose"
                :animationState="animationState" v-if="isShowModel">
                <template v-slot:content>
                    <div class="model_content">
                        测试机哦i啊问你在
                    </div>
                </template>
            </Model>
        </div>
    </div>
</template>
<script>
import {
    GetPersonHomeData
} from '@/api/index'
import Model from '/src/views/userInformation/components/model.vue'
import GuideMap from '/src/views/userInformation/components/GuideMap.vue'
export default {
    components: {
        Model,
        GuideMap
    },
    data() {
        return {
            Highlight: '',
            info: null,
            rightTitle: '',
            smallTitle: '',
            rightContent: '',
            indexList: [],
            type: null,
            isShowModel: false,
            isShowSource: false,
            animationState: true,
            currentIndexData: null,
            showContent: '',
            ModalBase: {
                boxHight: '38vmax',
                boxWidth: '48vmax',
                boxBgc: '#2B2C3D'
            },
            GuideMapData: {},
            userType: this.$query('userType')
        }
    },
    props: {

    },
    created() {
        this.getInfo()
    },
    mounted() {

    },
    methods: {
        hexToRgb(hex, opt) {
            if (hex) {
                hex = hex.replace('#', '')
                var red = parseInt(hex[0] + hex[1], 16)
                var green = parseInt(hex[2] + hex[3], 16)
                var blue = parseInt(hex[4] + hex[5], 16)
                return 'rgba(' + red + ',' + green + ',' + blue + ',' + opt + ')'
            }

        },
        changeBack() {
            this.isShowSource = false
        },
        changeModel() {
            // this.isShowModel = true
        },
        handleClose() {
            // 关闭弹窗--动画重置
            this.animationState = false
            setTimeout(() => {
                this.isShowModel = false
                this.animationState = true
            }, 400)
        },
        getInfo() {
            const customerCode = this.$query('params')
            let parames = {
                customerCode
            }
            GetPersonHomeData(parames).then(res => {
                this.info = res.data
                let familyData
                let lifeData
                let unBalanceList
                let organList
                if (res.data.familyData.length > 0 && res.data.familyData[0].balanceDetail.length > 0) {
                    familyData = res.data.familyData[0].balanceDetail
                }
                if (res.data.lifeData.length > 0) {
                    lifeData = res.data.lifeData.filter(v => v.isYellow == 1)
                }
                if (res.data.unBalanceList.length > 0) {
                    unBalanceList = res.data.unBalanceList.filter(v => v.unBalanceColorLevel)

                }
                if (res.data.organList.length > 0) {
                    organList = res.data.organList.filter(v => v.typeLevel)
                }
                this.GuideMapData = {
                    familyData,
                    lifeData,
                    unBalanceList,
                    organList
                }
                console.log('this.GuideMapData', this.GuideMapData)
            })

        },
        handleDiseaseItemClick(type, item, index) {
            console.log('disease', type, item, index);
            this.type = 4
            this.currentIndexData = type
            this.rightTitle = type == 1 ? '饮食结构' : '生活方式'
            this.smallTitle = item.lifeName
            this.rightContent = item.lifeDescription
            this.isShowSource = true
            this.Highlight = item.lifeName
        },
        handleOrganList(currentType, item, index) {
            console.log('organ', item, index)
            this.type = 4
            this.currentIndexData = currentType
            this.rightTitle = currentType == 1 ? `${item.typeName}介绍` : `${item.unBalanceTitle}介绍`
            this.smallTitle = null
            this.rightContent = currentType == 1 ? item.typeDescription : item.unBalanceDiscribe
            this.isShowSource = true
            this.Highlight = currentType == 1 ? `${item.typeName}` : `${item.unBalanceTitle}`
        },
        handleHistory(type, item, index) {
            console.log('history', type, item, index)
            this.currentIndexData = type
            this.type = type
            this.rightTitle = '来源依据'
            this.smallTitle = '问卷'
            this.rightContent = item.questionAnswer
            this.isShowSource = true
            this.Highlight = item.subUnBalanceTitle
            if (type == 3) {
                this.indexList = item
            } else {
                this.indexList = null
            }
        }

    },
    watch: {

    },
    computed: {

    },
}
</script>
<style lang='scss' scoped>
.PersonHome {
    display: flex;

    .PersonHome_left {
        overflow-y: auto;
        width: 48vmax;
        height: 59.4vmax;
        background: linear-gradient(91deg, #38394D 0%, #2B2C3C 100%);
        border-radius: 1vmax;
        margin-left: 1vmax;
        padding-left: 2.5vmax;
        padding-bottom: 2vmax;

        .PersonHome_left_title {
            display: flex;
            align-items: center;
            padding-top: 1.8vmax;

            .round {
                width: 0.8vmax;
                height: 0.8vmax;
                background: #18BFC8;
                border-radius: 50%;
            }

            .info {
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 1.6vmax;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
                text-transform: none;
                padding-left: 0.6vmax;
            }

            .info1 {
                margin-left: 1vmax;
                padding: 0.2vmax 0.6vmax;
                background: #20D0D9;
                border-radius: 0.5vmax;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 1.6vmax;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
                text-transform: none;
                padding-left: 0.6vmax;
            }
        }

        .PersonHome_left_content {
            padding-top: 1vmax;

            .text {
                padding-left: 0.8vmax;
                width: 4.8vmax;
                font-size: 1.2vmax;
            }
        }
    }

    .PersonHome_right {
        overflow-y: auto;
        width: 41vmax;
        height: 59.4vmax;
        background: #2B2C3C;
        border-radius: 1vmax;
        margin-left: 1vmax;

        .PersonHome_rgight_title {
            padding-left: 2.5vmax;
            display: flex;
            align-items: center;
            padding-top: 2.3vmax;

            .round {
                width: 0.8vmax;
                height: 0.8vmax;
                background: #18BFC8;
                border-radius: 50%;
            }

            .info {
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 1.6vmax;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
                text-transform: none;
                padding-left: 0.6vmax;
            }

            .info1 {
                margin-left: 1vmax;
                padding: 0.2vmax 0.6vmax;
                background: #20D0D9;
                border-radius: 0.5vmax;
                font-family: PingFang SC, PingFang SC;
                font-weight: 600;
                font-size: 1.6vmax;
                color: #FFFFFF;
                text-align: left;
                font-style: normal;
                text-transform: none;
                padding-left: 0.6vmax;
            }
        }

        .rightTitle_samll_title {
            font-size: 1.2vmax;
            padding-top: 1vmax;
            padding-left: 3.5vmax;

        }

        .PersonHome_right_content {
            padding-left: 3.5vmax;
            font-size: 1.2vmax;
            padding: 0 1.8vmax;
            opacity: .8;
            padding-left: 3.5vmax;
            padding-top: 0.6vmax;

        }
    }
}

.imgs {
    margin: 0 auto;
    width: 23.5vmax;
    height: 6.3vmax;
}

.disease {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;

    .disease_list {
        box-sizing: content-box;
        display: flex;
        flex-shrink: 0;
        align-items: center;
        margin-top: 1.7vmax;
        height: 3vmax;
        background: rgba(253, 214, 76, 0.1);
        border-radius: 0.8vmax;
        border: 1px solid rgba(253, 214, 76, 0.5);
        line-height: 3vmax;
        text-align: center;
        font-size: 1.2vmax;
        color: #FFFFFF;
        font-style: normal;
        text-transform: none;
        padding: 0 1.2vmax;
        margin-right: 1vmax;
        margin-top: 1.8vmax;

        .disease_list_round {
            flex-shrink: 0;
            width: 0.4vmax;
            height: 0.4vmax;
            background-color: #FFFFFF;
            border-radius: 50%;
        }

        .disease_list_text {
            flex-shrink: 0;
            padding-left: 0.3vmax;
        }
    }

    .acitive {
        border: 4px #20D0D9 solid !important;
    }

    .disease_item {
        width: 13.3vmax;
        height: 6.8vmax;
        margin-top: 1.8vmax;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
        background-color: #2B2C3D;
        border-radius: 2vmax;
        margin-right: 1vmax;
        padding-left: 1.5vmax;
        padding-right: 1vmax;
        padding-top: 1vmax;

        .disease_item_left {
            display: flex;

            .left_text {
                font-size: 1.2vmax;
            }

            .left_icon {
                width: 0.6vmax;
                height: 0.8vmax;
                margin-left: 0.5vmax;
                margin-top: 0.5vmax;
            }
        }

        .disease_item_right {
            width: 4.2vmax;
            height: 4.2vmax;
        }
    }
}
</style>